<template>
  <div class="home-v2-main-page">
    <div class="content-layout">

      <search :callback="search" />
      <div style="display: flex;align-items: center;padding-top: 10px;padding-bottom: 15px;padding-left:10px;background: #fff">

        <el-dropdown @command="changeJobTab">
          <span class="el-dropdown-link" style="margin-right: 20px;font-size: 20px">
            {{ jobTag }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="job in jobOptions" :key="job"
                              divided
                              :command="job">
              <div class="tab-job-item">
                <span :style="{'color':job === jobTag ? '#0052FE' : ''}" class="text">{{ job }}</span>
                <img v-if="userInfo && userInfo.vipJobTags && userInfo.vipJobTags.indexOf(job) > -1"
                     style="margin-left: 10px;width: 82px ; height: 20px;"
                     src="https://res.offertalks.cn/miniapp/icon-vip.png" />
              </div>

            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <div v-if="tags" class="tag-group">
          <div v-for="tag in tags" :key="tag.value"
               :class="['tag-item',selectTag === tag ? 'tag-item-selected' : '']"
               @click="selectTagEvent(tag)">{{ tag.text }}
          </div>
        </div>
      </div>

      <ul class="album-group"
          v-infinite-scroll="load"
          infinite-scroll-distance="30"
          :infinite-scroll-disabled="!hasMore">
        <album-card v-for="album in albumList" :key="album.id" :album-info="album" display="row"/>
      </ul>


    </div>
  </div>
</template>

<script>

import { getDictDetail } from "@/apis/dict";
import { searchAlbumListV2 } from "@/apis/module";
import AlbumCard from "@/components/v2/AlbumCard";
import Search from "@/components/Search";
import { mapGetters } from "vuex";

export default {
  components: { Search, AlbumCard },
  data() {
    return {
      tags: null,
      selectTag: null,
      page: 0,
      hasMore: true,
      albumList: [],
      loading: true,
      tagType: null,
      tabType: null,
      type: null,
      keyword: null,
      jobOptions: null,
      jobTag: null
    };
  },
  created() {
    this.initData();
    this.getTags();
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  methods: {
    search(keyword) {
      this.keyword = keyword;
      this.resetAlbumList();
    },
    initData() {
      const urlType = this.$route.params.type;
      this.tabType = "OfferTalks";
      this.tagType = "service_tags";
      if (urlType === "person") {
        // this.type = ["renmai"];
        this.tabType = "MasterTalks";
      } else if (urlType === "schedule") {
        // this.type = ["richeng"];
        this.tabType = "MoneyTalks";
      } else if (urlType === "user") {
        this.tabType = "UserTalks";
      }
      this.type = [this.tabType];
    },
    changeJobTab(e) {
      this.jobTag = e;
      this.resetAlbumList();
    },
    getTags() {
      if (this.tagType) {
        let totalSuc = 0;

        getDictDetail(this.tagType, this.tabType).then(res => {
          this.tags = res.data;
          totalSuc++;
          if (this.tags) {
            this.selectTag = this.tags[0];
          }
          this.checkSuc(totalSuc);
        });
        getDictDetail("job_tags", this.tabType).then(res => {
          console.log("job_tags", res.data);
          this.jobOptions = res.data.map(d => {
            return d.text;
          });
          this.jobTag = res.data[0].value;
          totalSuc++;
          this.checkSuc(totalSuc);
        });
      } else {
        this.resetAlbumList();
      }
    },
    checkSuc(totalSuc) {
      if (totalSuc >= 2) {
        this.resetAlbumList();
      }
    },
    selectTagEvent(tag) {
      this.selectTag = tag;
      this.resetAlbumList();
    },
    load() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.requestAlbumList();
      }
    },
    resetAlbumList() {
      this.page = 0;
      this.hasMore = true;
      this.albumList = [];
      this.requestAlbumList();
    },
    requestAlbumList() {
      let map = {};
      if (this.tagType) {
        map[this.tagType] = this.selectTag.value;
      }
      if (this.jobTag) {
        map.job_tags = this.jobTag;
      }
      console.log("map", map);
      searchAlbumListV2(map, this.keyword, this.page, this.type).then(res => {
        if (res.data.recommendAlbumList && res.data.recommendAlbumList.length > 0) {
          this.hasMore = true;
          this.albumList = this.albumList.concat(res.data.recommendAlbumList);
        } else {
          this.hasMore = false;
        }
        console.log(this.albumList);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.home-v2-main-page {
  padding: 30px;
  background-color: #F6F6F6;
  width: 100%;
  height: 100%;

  .content-layout {
    display: flex;
    overflow: auto;
    flex-direction: column;
    padding: 0px 40px 30px 40px;
    //background: #FFFFFF;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.04);
    border-radius: 2px;
    height: calc(100vh - 88px - 30px - 42px - 30px);


    .el-dropdown-link {
      cursor: pointer;
      color: #0052FE;
    }

    .el-icon-arrow-down {
      font-size: 18px;
    }

    .tag-group {
      display: flex;
      flex-wrap: wrap;
      row-gap: 20px;

      .tag-item {
        background-color: #f6f6f6;
        padding: 12px 30px;
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: normal;
        font-size: 18px;
        color: #333333;
        line-height: 24px;
        text-align: right;
        font-style: normal;
        margin-right: 20px;
        cursor: pointer;
      }

      .tag-item-selected {
        color: #0052FE;
        background-color: rgba(0, 82, 254, .1) !important;
      }
    }

    .album-group {
      z-index: 100;
      padding-top: 30px;
      margin-left: -30px;
      padding-left: 30px;
      //height: 100%;
      overflow: scroll;
      display: flex;
      flex-wrap: wrap;
      margin-block-start: 10px !important;
    }
  }



  .load-tip {
    text-align: center;
    font-size: 14px;
    color: #5e6d82;
    line-height: 20px;
    margin-top: 20px;
  }
}

.tab-job-item {
  display: flex;
  align-items: center;
  padding: 10px 20px;

  .text {
    font-size: 20px;
  }
}
</style>
